<template>
  <div class="jiaoxue">
    <headerNav></headerNav>
    <el-carousel :interval="5000" arrow="always" height="750px">
      <el-carousel-item v-for="item in bannerList" :key="item">
        <img :src="item.img" alt="" />
      </el-carousel-item>
    </el-carousel>
    <div class="main">
      <div class="linianBox">
        <div class="linianBoxTitle">爱与尊重，培育未来</div>
        <div class="linianBoxText">
          教育是一种充满爱与尊重的过程<br />
          燕京雍阳致力于为每个孩子创造充满温暖与关爱的学习环境。
        </div>
      </div>
      <div class="aiPei">
        <div class="title">爱的培育</div>
        <p>爱是燕京雍阳教育的灵魂，也是最高的教育境界。</p>
        <p>
          我们的老师们用满满的爱心教育每一位孩子，倾听孩子们的需求，分享他们的喜悦和挑战。
        </p>
        <p>
          爱让孩子们感受到温暖和安全，建立自信和自尊心。只有在爱的环境中，孩子们才能茁壮成长。
        </p>
        <p>教育工作不仅是一份职责，更是一份奉献。</p>
        <!-- <p>
          燕京雍阳的老师们将满满的爱注入每个教育瞬间，为孩子们的未来播下希望的种子。
        </p>
        <p>
          我们愿意为孩子们的成长倾注所有的心血，因为爱是我们教育的最大动力。
        </p> -->
      </div>
      <div class="zunzhong">
        <div class="title">尊重的教育</div>
      
        <p>燕京雍阳深知每个孩子都是独一无二的个体，拥有自己的特点和需求。</p>
        <p>因此，我们以尊重作为教育的出发点。</p>
        <p>
          尊重孩子的个性、思维方式和学习节奏，为他们提供一个自由表达、发展潜力的空间。
        </p>
        <p>通过尊重激励孩子们更多地去探索、学习和成长。</p>
        <!-- <p>在尊重的氛围中，孩子们能够更自信、更勇敢地去创造。</p>
        <p>我们鼓励他们提出问题、思考解决方案，培养他们的创造力和创新思维。</p> -->
        <p>尊重让孩子们相信自己可以改变世界，成为未来的领袖和创新者。</p>
      </div>
      <div class="aiPei peiyue">
        <div class="title">培育未来</div>
        <p>我们不仅教授知识，</p>
        <p>更注重培养孩子的综合素养。</p>
        <p>我们鼓励孩子们勇于探索，</p>
        <p>培养他们的创新精神和问题解决能力。</p>
        <p>我们的教学理念是，通过爱与尊重，</p>
        <p>培育具备批判性思维、创造力和全球视野的未来领袖。</p>
      </div>
      <div class="zai">
        <div class="zaiP">
          <div class="title">在燕京雍阳：</div>
          <p>我们不仅致力于培养学术能力，更注重培养孩子的品德和价值观。</p>
          <div class="title">我们相信：</div>
          <p>爱与尊重是孩子健康成长的土壤，也是他们成为更好的自己的动力。</p>
        </div>
      </div>
    </div>
    <footerBtm></footerBtm>
  </div>
</template>

<script>
import headerNav from "@/components/nav/nav";
import footerBtm from "@/components/footer";
export default {
  name: "jiaoxue",
  components: { headerNav, footerBtm },
  data() {
    return {
      bannerList: [],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.api.allPage({ name: '教学理念' }).then((res) => {})
    // banner
    this.api.banner({ remark1: 2 }).then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].img = JSON.parse(res.data[i].img);
      }
      this.bannerList = res.data;
    });
  },
  methods: {},
};
</script>
<style scoped>
.el-carousel,
.el-carousel__item,
.el-carousel img {
  width: 100%;
}
.main {
  width: 100%;
  height: 2832px;
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/jxTiao.png");
  background-size: 100% 100%;
  position: relative;
  padding-top: 32px;
}

.aiPei {
  width: 1280px;
  /* height: 486px; */
  background: #fffde8;
  border-radius: 300px;
  position: absolute;
  top: 460px;
  left: 50%;
  transform: translateX(-50%);
}
.title {
  font-size: 60px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
  margin-top: 49px;
  margin-bottom: 40px;
}
.aiPei .title {
  color: #975b00;
}
.aiPei p {
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #975b00;
  line-height: 48px;
}

.zunzhong {
  width: 1280px;
  height: 486px;
  background: #e3fff7;
  border-radius: 300px;
  position: absolute;
  top: 1006px;
  left: 50%;
  transform: translateX(-50%);
}
.zunzhong .title {
  color: #00885f;
}
.zunzhong p {
  text-align: center;
  font-size: 24px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #00885f;
  line-height: 45px;
}
.peiyue {
  position: absolute;
  top: 1552px;
  left: 50%;
  transform: translateX(-50%);
}

.zai {
  width: 1108px;
  height: 429px;
  position: absolute;
  top: 2171px;
  left: 50%;
  transform: translateX(-50%);
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/jiaoxuelinian/jiaoxuelinianlvkuang.png");
  background-size: 100% 100%;
}
.zaiP {
  width: 960px;
  height: 283px;
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #975b00;
  line-height: 70px;
  margin-top: 62px;
  margin-left: 105px;
}
.zaiP .title {
  font-size: 40px;
  font-weight: 500;
  width: 80%;
  margin: 0 auto;
  text-align: left;
}

.linianBox {
  width: 1200px;
  height: 280px;
  background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/jiaoxuelinian/jiaoxueLinianTitleImg.png");
  background-size: 100% 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 40px;
  box-sizing: border-box;
}
.linianBox .linianBoxTitle {
  font-size: 60px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 600;
  color: #25ae85;
}
.linianBox .linianBoxText {
  font-size: 40px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #975b00;
  line-height: 80px;
  padding-top: 60px;
}

@media (min-width: 1366px) and (max-width: 1440px) {
  .aiPei {
    padding-bottom: 20px;
    box-sizing: border-box;
  }
  .aiPei .title {
    font-size: 50px;
  }
  .zunzhong {
    width: 1280px;
    height: 406px;
    background: #e3fff7;
    border-radius: 300px;
    position: absolute;
    top: 1006px;
    left: 50%;
  }
  .zunzhong .title {
    font-size: 50px;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 563px;
  }
  div /deep/ .el-carousel__container {
    height: 563px !important;
  } 
}

@media (min-width: 1281px) and (max-width: 1366px) {
  .main{
    height: 2015px;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 534px;
  }
  div /deep/ .el-carousel__container {
    height: 534px !important;
  }
  .linianBox{
    width: 910px;
    height: 200px;
    padding-top: 23px;
  }
  .linianBox .linianBoxTitle{
    font-size: 43px;
  }
  .linianBox .linianBoxText{
    font-size: 28px;
    padding-top: 23px;
  }
  .aiPei{
    width: 911px;
    height: 346px;
    top: 327px;
  }
  .aiPei .title{
    font-size: 43px;
    margin-top: 35px;
    margin-bottom: 28px;
  }
  .aiPei p{
    font-size: 17px;
    line-height: 34px;
  }
  .peiyue{
    top: 1552px;
  }

  .zunzhong{
    width: 911px;
    height: 346px;
    top: 716px;
  }
  .zunzhong .title{
    font-size: 43px;
    margin-top: 35px;
    margin-bottom: 28px;
  }
  .zunzhong p{
    font-size: 17px;
    line-height: 34px;
  }

  .peiyue{
    top: 1104px;
  }

  .zai{
    top: 1580px;
    width: 788px;
    height: 305px;
  }
  .zaiP{
    height: 201px;
    font-size: 23px;
    margin-top: 44px;
    margin-left: 75px;
  }
  .zaiP .title{
    font-size: 28px;
    width: 90%;
    line-height: 40px;
  }
}

@media (min-width: 1080px) and (max-width: 1280px) {
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 500px;
  }
  div /deep/ .el-carousel__container {
    height: 500px !important;
  }
  .main{
    height: 1888px;
  }
  .linianBox{
    width: 800px;
    height: 187px;
    padding-top: 25px;
  }
  .linianBox .linianBoxTitle{
    font-size: 40px;
  }
  .linianBox .linianBoxText{
    font-size: 27px;
    line-height: 53px;
    padding-top: 21px;
  }
  .aiPei{
    width: 853px;
    height: 324px;
    top: 307px;
  }
  .title{
    font-size: 40px;
    margin-top: 33px;
    margin-bottom: 27px;
    line-height: 40px;
  }
  .aiPei p{
    font-size: 16px;
    line-height: 42px;
  }
  .peiyue{
    top: 1035px;
  }
  .peiyue p{
    line-height: 32px;
  }
  .zunzhong{
    width: 853px;
    height: 324px;
    top: 671px;
  }
  .zunzhong p{
    font-size: 16px;
    line-height: 42px;
  }
  .zai{
    width: 739px;
    height: 286px;
    top: 1447px;
  }
  .zaiP{
    margin-top: 45px;
    margin-left: 70px;
  }
  .zaiP .title{
    width: 86%;
    font-size: 27px;
  }
  .zaiP p{
    font-size: 21px;
    line-height: 47px;
  }
}


@media screen and (max-width: 750px) {
  div /deep/ .el-carousel__container {
    height: 146px !important;
  }
  div /deep/ .el-carousel__container img {
    height: 100% !important;
  }
  .el-carousel,
  .el-carousel__item,
  .el-carousel img {
    width: 100%;
    height: 146px;
  }
  .main {
    height: 1121px;
    background-image: url("https://lwjy.oss-cn-beijing.aliyuncs.com/school/jiaoxuelinian/PhoneJiaoxueDi.png");
  }
  .linianBox {
    width: 355px;
    height: 93px;
    padding-top: 14px;
  }
  .linianBox .linianBoxTitle {
    font-size: 20px;
  }
  .linianBox .linianBoxText {
    font-size: 13px;
    line-height: 27px;
    padding-top: 20px;
  }
  .aiPei {
    width: 343px;
    height: 193px;
    top: 198px;
  }
  .title {
    font-size: 20px;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 13px;
  }
  .aiPei p {
    font-size: 12px;
    line-height: 20px;
  }

  .zunzhong {
    width: 343px;
    height: 233px;
    top: 418px;
  }
  .zunzhong p {
    width: 80%;
    font-size: 12px;
    line-height: 20px;
    margin: 0 auto;
  }

  .peiyue {
    top: 678px;
  }

  .zai {
    width: 343px;
    height: 222px;
    top: 907px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .zaiP {
    width: 300px;
    height: 140px;
    font-size: 12px;
    margin: 0;
    line-height: 23px;
  }
  .zaiP .title {
    font-size: 13px;
  }
  .peiyue .title {
    margin-top: 15px;
    margin-bottom: 13px;
  }
  .peiyue p {
    width: 85%;
    text-align: center;
    margin: 0 auto;
  }
}
</style>